<template>
  <el-dialog
    visible
    top="30vh"
    :show-close="false"
    append-to-body
    custom-class="rounded mb-0 payment-delete-modal"
    @close="$emit('close')"
  >
    <template #title>
      <div class="p-4">
        <div class="d-flex justify-content-between align-items-center">
          <span>
            <h2>{{ $t('eventMapModal.paymentCard.deletePaymentModal.header') }}</h2>
          </span>
          <span>
            <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
              <CloseIcon />
            </Button>
          </span>
        </div>
      </div>
    </template>
    <div class="d-flex flex-column px-4">
      <div>
        <el-form ref="form" :model="formModel" :show-message="false">
          <el-form-item
            :label="$t('eventMapModal.paymentCard.deletePaymentModal.reason')"
            prop="reason"
            class="m-0"
            required
          >
            <el-select
              v-model="formModel.reason"
              :popper-append-to-body="false"
              class="reason-dropdown"
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="reason in reasonOptions"
                :key="reason.key"
                :label="$t(`eventMapModal.paymentCard.deletePaymentModal.reasonOptions.${reason.label}`)"
                :value="reason.key"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('eventMapModal.paymentCard.deletePaymentModal.details')" prop="comment" required>
            <el-input
              v-model="formModel.comment"
              class="details-area mt-1"
              resize="none"
              type="textarea"
              :maxlength="200"
              :placeholder="$t('eventMapModal.paymentCard.deletePaymentModal.detailsPlaceHolder')"
              :autosize="{ minRows: 5 }"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>

    <template #footer>
      <Button type="secondary" @click="$emit('close')">
        {{ $t('commons.cancel') }}
      </Button>
      <Button type="danger" class="delete-button" @click="handleDelete">
        {{ $t('eventMapModal.paymentCard.deletePaymentModal.delete') }}
      </Button>
    </template>
  </el-dialog>
</template>

<script>
import { getCurrentInstance, reactive } from 'vue';

import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';

const DELETE_REASONS = {
  CLIENT_MISTAKE: 'clientMistake',
  SUPPORT_MISTAKE: 'supportMistake',
  PAYMENTS_OPERATIONS_MISTAKE: 'paymentsOperationsMistake',
  OTHER: 'other',
};

export default {
  components: {
    Button,
    CloseIcon,
  },
  props: {
    payment: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;

    const formModel = reactive({ id: props.payment.id, reason: null, comment: null });

    const reasonOptions = Object.entries(DELETE_REASONS).map(([key, label]) => ({ key, label }));

    const handleDelete = async () => {
      root.$refs.form.validate(async (valid) => {
        if (valid) {
          emit('delete-payment', formModel);
        }
      });
    };

    return {
      formModel,
      reasonOptions,
      handleDelete,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

.details-area {
  word-break: break-word;
}

::v-deep .payment-delete-modal {
  width: 400px;
  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
    color: $typography-primary;
  }
}

::v-deep .el-scrollbar__wrap {
  overflow: hidden;
}
</style>
