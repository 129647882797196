<template>
  <div>
    <div class="d-flex justify-content-between align-content-center mb-4">
      <h3>{{ translate('title') }}</h3>
      <Button v-if="unmatch" type="secondary" @click="$emit('open-match-modal')"
        ><p class="fw-bold">{{ translate('matchPaymentWithInvoices') }}</p></Button
      >
    </div>
    <Table :columns="columns" :data="tableData" show-index border rounded :hover="false">
      <template #cell-reference="{ rowData: { reference: document } }">
        <a v-if="document.id" href="#" @click.prevent.stop="$emit('open-document', document.id)">
          {{ document.type ? $t(`document.exports.schema.type.shortName.${document.type}`) : '' }}
          {{ document.documentNumber }}
        </a>
        <p v-else-if="document">{{ document }}</p>
        <p v-else>-</p>
      </template>
    </Table>
  </div>
</template>

<script>
import { options } from '@/locale/dateConfig';
import { Table, Button } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

const TABLE_HEADERS = {
  DATE: 'date',
  REFERENCE: 'reference',
  TOTAL_AMOUNT: 'totalAmount',
  PAID_AMOUNT: 'paidAmount',
};

export default {
  components: { Table, Button },
  props: {
    billingLinks: { type: Array, default: () => [] },
    unmatch: { type: Boolean, default: false },
  },
  setup() {
    const { formatToCurrency } = useCurrency();

    return { formatToCurrency };
  },
  computed: {
    columns() {
      return [
        { header: this.translate('table.headers.date'), key: TABLE_HEADERS.DATE, width: '130px' },
        {
          header: this.translate('table.headers.reference'),
          key: TABLE_HEADERS.REFERENCE,
          width: this.$direction === 'ltr' ? '250px' : '180px',
        },
        { header: this.translate('table.headers.totalAmount'), key: TABLE_HEADERS.TOTAL_AMOUNT, width: '140px' },
        { header: this.translate('table.headers.paidAmount'), key: TABLE_HEADERS.PAID_AMOUNT },
      ];
    },
    tableData() {
      return this.billingLinks.map(({ amount, date, reference, billing }) => ({
        id: billing?.id,
        [TABLE_HEADERS.DATE]: billing ? this.formatDate(billing?.date) : this.formatDate(date),
        [TABLE_HEADERS.REFERENCE]: billing ? billing?.source?.document : reference,
        [TABLE_HEADERS.TOTAL_AMOUNT]: billing?.totalAmount ? this.formatMoney(billing.totalAmount / 100) : '-',
        [TABLE_HEADERS.PAID_AMOUNT]: this.formatMoney(amount),
      }));
    },
  },
  methods: {
    translate(key) {
      return this.$t(`eventMapModal.paymentCard.billingTable.${key}`);
    },
    formatDate(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, { ...options.short, timeZone: 'UTC' })
        : this.$t('commons.unknownDate');
    },
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },
  },
};
</script>
