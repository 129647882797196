<template>
  <el-dialog
    visible
    top="30vh"
    :show-close="false"
    append-to-body
    custom-class="rounded mb-0 payment-edit-modal"
    @close="$emit('close')"
  >
    <template #title>
      <div class="p-4 border-bottom">
        <div class="d-flex justify-content-between align-items-center">
          <span>
            <h2>{{ $t('eventMapModal.paymentCard.editModal.header') }}</h2>
          </span>
          <span>
            <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
              <CloseIcon />
            </Button>
          </span>
        </div>
      </div>
    </template>
    <div v-loading="loading" class="d-flex flex-column gap-4 p-4 mb-7">
      <div>
        <label class="d-block mb-2">{{ $t('eventMapModal.paymentCard.paymentBox.date') }}</label>
        <el-date-picker
          v-model="paymentForm.date"
          :clearable="false"
          value-format="yyyy-MM-dd"
          size="small"
          type="date"
          :picker-options="getDatePickerOptions()"
          :format="$direction === 'ltr' ? 'MM.dd.yyyy' : 'dd.MM.yyyy'"
          :placeholder="`${$t('commons.select')}...`"
        />
      </div>
    </div>

    <template #footer>
      <Button :disabled="loading" type="secondary" @click="$emit('close')">
        {{ $t('commons.cancel') }}
      </Button>
      <Button :disabled="loading" type="primary" @click="save">
        {{ $t('commons.save') }}
      </Button>
    </template>
  </el-dialog>
</template>

<script>
import { equals } from 'ramda';

import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { usePaymentPatch } from '../../../compositions/payment';

export default {
  components: {
    Button,
    CloseIcon,
  },
  props: {
    payment: { type: Object, required: true },
  },
  setup() {
    const { patchPayment, loading } = usePaymentPatch();

    return {
      patchPayment,
      loading,
    };
  },
  data() {
    return {
      paymentForm: {
        date: this.payment.date,
      },
    };
  },
  methods: {
    isFormDirty() {
      const initialForm = {
        date: this.payment.date,
      };
      return !equals(initialForm, this.paymentForm);
    },
    async save() {
      if (this.isFormDirty()) {
        await this.patchPayment({
          id: this.payment.id,
          patchParams: {
            date: this.paymentForm.date,
          },
        });
      } else this.$emit('close');
    },
    getDatePickerOptions() {
      const today = new Date();
      return {
        disabledDate: (date) => date > today,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

::v-deep .payment-edit-modal {
  width: 350px;
  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
    color: $typography-primary;
  }
}
</style>
