<template>
  <div class="event-modal-card-boxes">
    <Box :title="translate('detailsBox.title')" class="d-flex flex-column">
      <div class="d-flex justify-content-between">
        <p>{{ translate('detailsBox.billingsPaid') }}</p>
        <p>{{ payment.billingLinks.length }}</p>
      </div>
      <hr class="event-modal-card-boxes-hr--doted mt-auto" />
      <div class="d-flex justify-content-between">
        <p class="fw-bold">{{ translate('detailsBox.totalAmount') }}</p>
        <p class="fw-bold">{{ formatMoney(payment.amount * (payment.isRefund ? -1 : 1)) }}</p>
      </div>
    </Box>
    <Box :title="translate('paymentBox.title')" class="d-flex flex-column justify-content-between">
      <div class="h-100 d-flex flex-column gap-2">
        <p v-if="payment.type === 'balance'">{{ translate('paymentBox.noPaymentMethod') }}</p>
        <template v-else>
          <div class="d-flex justify-content-between">
            <p>{{ translate('paymentBox.paymentMethod') }}</p>
            <p>
              {{ translate(`paymentBox.${payment.type}`) }} -
              {{ $t(`payment.exports.paymentMethods.shortName.${transaction.paymentMethod}`) }}
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ translate('paymentBox.reference') }}</p>
            <p>{{ transaction.reference || '-' }}</p>
          </div>
          <div
            v-if="payment.requestedDate && currentTenant.type !== BUSINESS_TYPES.supplier"
            class="d-flex justify-content-between"
          >
            <p>{{ translate('paymentBox.requestedDate') }}</p>
            <p>{{ formatDate(payment.requestedDate) }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ translate('paymentBox.date') }}</p>
            <p>{{ formatDate(payment.date) }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="fw-bold">{{ translate('paymentBox.transactionDate') }}</p>
            <p class="fw-bold">{{ formatDate(transaction.date) }}</p>
          </div>
        </template>
      </div>
    </Box>
    <SummaryBox
      :documents="documents"
      :supplier-name="payment.supplier.name"
      :customer-name="tenantName"
      :type="summaryBoxType"
    >
      <template v-if="unbilledPayments.length">
        <p>
          {{
            $t('eventMapModal.paymentCard.alertBox.error.existingDiffs', {
              paidAmount: formatMoney(unbilledPayments[0].paidAmount),
              billedAmount: formatMoney(unbilledPayments[0].billedAmount),
            })
          }}
        </p>
        <p>
          {{
            $t('eventMapModal.paymentCard.alertBox.error.existingDiffsAmount', {
              amount: formatMoney(unbilledPayments[0].paidAmount - unbilledPayments[0].billedAmount),
            })
          }}
        </p>
      </template>
      <template v-else>{{ $t(`eventMapModal.paymentCard.alertBox.success.matchingStatus`) }}</template>
    </SummaryBox>
  </div>
</template>

<script>
import { computed } from 'vue';

import { options } from '@/locale/dateConfig';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useTenancy } from '@/modules/auth';
import { BUSINESS_TYPES } from '@/modules/purchase-management/tools/constants';

import { SummaryBox } from '../../../commons/components';
import Box, { BOX_TYPE } from '../../../commons/components/Box.vue';
import { useBusinessById } from '../../../compositions';

export default {
  components: { Box, SummaryBox },
  props: {
    payment: { type: Object, required: true },
    unbilledPayments: { type: Array, default: () => [] },
  },
  setup(props) {
    const businessId = computed(() => props.payment.businessId);
    const { business } = useBusinessById(businessId);
    const { formatToCurrency } = useCurrency();
    const { currentTenant } = useTenancy();

    return {
      BUSINESS_TYPES,
      currentTenant,
      formatToCurrency,
      summaryBoxType: computed(() => {
        return props.unbilledPayments.length ? BOX_TYPE.ERROR : BOX_TYPE.SUCCESS;
      }),
      documents: computed(() => [
        {
          id: props.payment.eventReferences[0]?.documentId,
          type: 'document',
          documentNumber: props.payment.eventReferences[0]?.reference,
        },
      ]),
      tenantName: computed(() => business.value?.name || ''),
    };
  },
  computed: {
    transaction() {
      return this.payment.transactions[0];
    },
  },
  methods: {
    translate(key) {
      return this.$t(`eventMapModal.paymentCard.${key}`);
    },
    formatDate(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, { ...options.short, timeZone: 'UTC' })
        : this.$t('commons.unknownDate');
    },
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },
  },
};
</script>

<style scoped lang="scss" src="../../../commons/style.scss" />
