<template>
  <div class="h-100" @click="closeContactPopover">
    <MainLayout :actions="actions" :loading="loading" v-bind="$props" v-on="$listeners" @action="actionsHandler">
      <template #header>
        <h1>
          {{ $t('eventMapModal.paymentCard.header') }}
        </h1>
        <div v-if="business && supplier" class="d-flex flex-row align-items-center">
          <el-popover :value="contactPopover" trigger="manual" placement="bottom" popper-class="p-0">
            <ContactCard
              v-if="contactUser"
              :user="contactUser"
              :customer="business"
              :supplier="supplier"
              :token="token"
            />
            <template #reference>
              <Button
                type="link"
                class="text-typography-primary p-0"
                @click.stop="openContactPopover({ type: 'tenant', payload: { worksAt: business.id } })"
              >
                {{ business.name }}
              </Button>
              <ExchangeIcon class="mx-1" />
              <Button
                type="link"
                class="text-typography-primary p-0"
                @click.stop="
                  openContactPopover({
                    type: 'supplier',
                    payload: { worksAt: supplier.id, businessId: business.id },
                  })
                "
              >
                {{ supplier.name }}
              </Button>
            </template>
          </el-popover>
        </div>
        <p>{{ formatDate(payment.date || payment.requestedDate) || $t('commons.unknownDate') }}</p>
      </template>
      <template #content>
        <div class="h-100 overflow-scroll">
          <Boxes :payment="payment" :unbilled-payments="unbilledPayments" @open-document="selectedDocId = $event" />
          <BillingTable
            :billing-links="payment.billingLinks"
            :unmatch="!!unbilledPayments.length"
            @open-document="selectedDocId = $event"
            @open-match-modal="matchPaymentModalSupplier = supplier"
          />
        </div>
        <DocumentModal v-if="selectedDocId" visible :document-id="selectedDocId" @close="selectedDocId = null" />
        <PaymentDateModal v-if="paymentDateModalOpen" :payment="payment" @close="paymentDateModalOpen = false" />
        <PaymentMethodModal
          v-if="paymentMethodModalOpen"
          :loading="updatingPayment"
          :credit-cards="creditCards"
          :supplier-account="supplierBankAccount"
          :initial-payment-method="transaction"
          @close="paymentMethodModalOpen = false"
          @paymentMethodUpdate="handlePaymentMethodUpdate"
        />
        <MatchPaymentModal
          v-if="matchPaymentModalSupplier"
          :payment="unbilledPayments[0]"
          :supplier="matchPaymentModalSupplier"
          @close="onCloseMatchPaymentModal"
        />
        <DeletePaymentModal
          v-if="paymentDeleteModalOpen"
          :payment="payment"
          @close="paymentDeleteModalOpen = false"
          @delete-payment="handleDeletePayment"
        />
      </template>
    </MainLayout>
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { omit, reject, isNil, equals } from 'ramda';

import { options } from '@/locale/dateConfig';
import { DocumentModal } from '@/modules/documentModal';
import { useSupplier } from '@/modules/suppliers';
import { useBankAccounts, useCreditCards } from '@/modules/payment';
import { Button } from '@/modules/core';
import { useGlobalPermissions } from '@/modules/permissions';
import { ExchangeIcon } from '@/assets/icons';
import { useTenancy } from '@/modules/auth';
import { ContactCard } from '@/modules/contact';

import { usePaymentData, usePaymentPatch, useUnbilledPayments, usePaymentDelete } from '../../compositions/payment';
import { Boxes, BillingTable, PaymentDateModal, DeletePaymentModal } from './components';
import MainLayout from '../MainLayout';
import { useBusinessById } from '../../compositions';

const ACTION_EVENTS = {
  EDIT_PAYMENT_DATE: 'editDate',
  EDIT_PAYMENT_METHOD: 'editPaymentMethod',
  DELETE_PAYMENT: 'deletePayment',
};

export default {
  components: {
    MainLayout,
    DocumentModal,
    Boxes,
    BillingTable,
    PaymentDateModal,
    DeletePaymentModal,
    Button,
    ExchangeIcon,
    ContactCard,
    PaymentMethodModal: () => import('@/modules/payment/customer/components/PaymentMethodModal'),
    MatchPaymentModal: () => import('@/modules/payment/customer/components/MatchPaymentModal'),
  },
  props: {
    eventId: { type: String, required: true },
    eventType: { type: String, required: true },
  },

  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const matchPaymentModalSupplier = ref(null);
    const { token, currentTenant } = useTenancy();

    const { payment, loading: paymentLoading, refetch: paymentRefetch } = usePaymentData(props.eventId);
    const { supplier, loading: supplierLoading } = useSupplier(computed(() => payment.value.supplierId));
    const { business, loading: businessLoading } = useBusinessById(computed(() => payment.value.businessId));
    const { bankAccounts } = useBankAccounts(computed(() => payment.value?.supplierId));
    const businessId = computed(() => payment.value?.businessId);
    const { hasPaymentDeleteDocumentedEvent, hasPaymentEditDocumentedEvent, hasPaymentExtended } =
      useGlobalPermissions();
    const { creditCards } = useCreditCards(computed(() => businessId.value));

    const supplierBankAccount = computed(() => bankAccounts.value[0]);

    const {
      unbilledPayments,
      loading: unbilledPaymentsLoading,
      refetch: unbilledPaymentsRefetch,
    } = useUnbilledPayments(computed(() => ({ businessId: businessId.value, paymentId: payment.value.id })));

    const { patchPayment, loading: updatingPayment, onDone, onError } = usePaymentPatch();
    const { deletePayment, onDone: onPaymentDeleteDone, onError: onPaymentDeleteError } = usePaymentDelete();
    const paymentDateModalOpen = ref(false);
    const paymentMethodModalOpen = ref(false);
    const paymentDeleteModalOpen = ref(false);
    onError(() => root.$message.error(root.$t('commons.messages.action.error')));
    onDone(() => {
      paymentDateModalOpen.value = false;
      paymentMethodModalOpen.value = false;
      root.$message.success(root.$t('commons.messages.action.success'));
    });

    onPaymentDeleteDone(() => {
      paymentDeleteModalOpen.value = false;
      root.$message.success(root.$t('eventMapModal.paymentCard.messages.deletedSuccessfully'));
      emit('close');
    });
    onPaymentDeleteError(() => root.$message.error(root.$t('commons.messages.action.error')));

    return {
      token,
      business,
      businessLoading,
      creditCards,
      patchPayment,
      deletePayment,
      payment,
      supplier,
      supplierLoading,
      supplierBankAccount,
      updatingPayment,
      formatDate: (ms) => new Date(ms).toLocaleDateString(root.$i18n.locale, { ...options.long, timeZone: 'UTC' }),
      loading: computed(() => paymentLoading.value || supplierLoading.value || unbilledPaymentsLoading.value),
      unbilledPayments,
      selectedDocId: ref(),
      paymentMethodModalOpen,
      paymentDateModalOpen,
      paymentDeleteModalOpen,
      matchPaymentModalSupplier,
      paymentRefetch,
      unbilledPaymentsRefetch,
      hasPaymentDeleteDocumentedEvent,
      hasPaymentEditDocumentedEvent,
      hasPaymentExtended,
      contactPopover: ref(false),
      contactUser: ref(null),
      actions: computed(() => {
        return payment.value.type === 'documentation' && currentTenant.value.type !== 'SUPPLIER'
          ? Object.values(ACTION_EVENTS).map((event) => {
              const noPermission =
                (event === ACTION_EVENTS.DELETE_PAYMENT && !hasPaymentExtended.value) ||
                (event === ACTION_EVENTS.DELETE_PAYMENT && !hasPaymentDeleteDocumentedEvent.value) ||
                (event === ACTION_EVENTS.EDIT_PAYMENT_METHOD && !hasPaymentEditDocumentedEvent.value);
              return {
                event,
                label: root.$t(`eventMapModal.paymentCard.moreActions.${event}`),
                noPermission,
                disabled: noPermission,
              };
            })
          : [];
      }),
    };
  },
  computed: {
    transaction() {
      const transaction = this.payment.transactions[0] ?? {};
      return reject(isNil, omit(['__typename', 'id'], transaction));
    },
  },
  methods: {
    actionsHandler(event) {
      switch (event) {
        case ACTION_EVENTS.EDIT_PAYMENT_DATE:
          this.paymentDateModalOpen = true;
          break;
        case ACTION_EVENTS.EDIT_PAYMENT_METHOD:
          this.paymentMethodModalOpen = true;
          break;
        case ACTION_EVENTS.DELETE_PAYMENT:
          this.paymentDeleteModalOpen = true;
          break;
        default:
          break;
      }
    },
    async handlePaymentMethodUpdate(newTransaction) {
      if (!equals(this.transaction, newTransaction))
        await this.patchPayment({
          id: this.payment.id,
          patchParams: {
            transactions: [newTransaction],
          },
        });
      else this.paymentMethodModalOpen = false;
    },
    onCloseMatchPaymentModal() {
      this.matchPaymentModalSupplier = null;
      this.paymentRefetch();
      this.unbilledPaymentsRefetch();
    },
    async handleDeletePayment({ id, reason, comment }) {
      await this.deletePayment({ id, reason, comment });
    },
    openContactPopover(user) {
      this.contactPopover = true;
      this.contactUser = user;
    },
    closeContactPopover() {
      this.contactPopover = false;
    },
  },
};
</script>

<style scoped lang="scss" src="../../commons/style.scss" />
